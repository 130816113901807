import React, { useState } from "react"
import Typography from "../Typography/Typography"
import FaqCard from "./components/FaqCard"
import faq from "../.././../jsonDatabase/faq.json"
import "./FaqQuestionsSection.css"
import { useTranslation } from "react-i18next"

const FaqQuestionsSection = () => {
  const { t } = useTranslation("common")
  const [currentCategory, setCurrentCategory] = useState("general")
  const [questionRenderLimit, setQuestionRenderLimit] = useState(7)

  return (
    <div className="faq-questions-section-root">
      <Typography
        title
        category="section-title"
        text={t("faq.title")}
        style={{ marginBottom: 60 }}
      />
      <div className="faq-questions-container">
        <div>
          <div className="faq-categories-container">
            {faq.map((faqData, i) => (
              <div
                key={i}
                className={`faq-category ${
                  currentCategory === faqData.category ? "active" : null
                }`}
                onClick={() => setCurrentCategory(faqData.category)}
              >
                <Typography category="register-button" text={faqData.category} />
              </div>
            ))}
          </div>
        </div>
        <div className="faq-questions-right-section">
          <div className="faq-categorize-questions-container">
            {faq
              .filter((faqData) => faqData.category === currentCategory)[0]
              .questions.map(
                (currentCategoryQuestion, i) =>
                  i < questionRenderLimit && (
                    <FaqCard
                      key={currentCategoryQuestion.question}
                      question={currentCategoryQuestion.question}
                      answer={currentCategoryQuestion.answer}
                      isCategoryRules={currentCategory === "rules"}
                    />
                  )
              )}
          </div>
          {faq
            .filter((faqData) => faqData.category === currentCategory)
            .map(
              (currentCategoryQuestions) => currentCategoryQuestions.questions.length
            ) > questionRenderLimit && (
            <div
              className="load-more-button-container"
              onClick={() => setQuestionRenderLimit(questionRenderLimit + 3)}
            >
              <Typography text={t("load.more")} category="pink-button" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FaqQuestionsSection
