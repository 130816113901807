import React from "react"
import Facebook from "../../../images/svg/facebook_icon.svg"
import Linkedin from "../../../images/svg/linkedin-white.svg"
import { FacebookShareButton, LinkedinShareButton } from "react-share"
import "./ModalCard.css"
const ModalCard = ({ closeModal }) => {
  return (
    <div className="modal-card-root">
      <div className="modal-icons-container">
        <FacebookShareButton
          quote="Ready to develop real, fun but also challenging web applications? Endurance hosts its first global participants this year - an experience that is for endurers in software development!"
          url={window.location.href}
        >
          <img
            src={Facebook}
            alt="endurance-facebook"
            className="modal-card-facebook-icon"
          />
        </FacebookShareButton>
        <LinkedinShareButton
          title="Endurance"
          summary="Ready to develop real, fun but also challenging web applications? Endurance hosts its first global participants this year - an experience that is for endurers in software development!"
          url={window.location.href}
        >
          <img
            src={Linkedin}
            alt="endurance-facebook"
            className="modal-card-linkedin-icon"
          />
        </LinkedinShareButton>
      </div>
      <div className="close-button" onClick={closeModal}></div>
    </div>
  )
}

export default ModalCard
