import { withStyles } from "@material-ui/core/styles"
import Switch from "@material-ui/core/Switch"

const ToggleSwitch = withStyles(() => ({
  root: {
    width: 40,
    height: 22,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 3,
    color: "#F2F2F2",
    transition: `transform 0.3s ease-in`,

    "&$checked": {
      transform: "translateX(18px)",
      color: "#63509B",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#F2F2F2",
        borderColor: "F2F2F2",
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
    left: 3,
    top: 3,
  },
  track: {
    border: `1px solid #F2F2F2`,
    borderRadius: 500,
    opacity: 1,
    backgroundColor: "#141414",
    boxSizing: "border-box",
  },
  checked: {},
}))(Switch)

export default ToggleSwitch
