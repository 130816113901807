import React, { useState, useEffect } from "react"
import { FormControl, Select, MenuItem } from "@material-ui/core"
import i18n from "../../../i18n"
import "./LanguageDropdownButton.css"

const LanguageDropdownButton = ({ isMobile }) => {
  const [language, setLanguage] = useState("")
  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng)
    i18n.changeLanguage(lng)
    setLanguage(lng)
  }
  useEffect(() => {
    setLanguage(localStorage.getItem("language"))
  }, [])
  return (
    <div
      className={`translation-language-container ${
        isMobile ? "mobile-position" : "desktop-position"
      }`}
    >
      <FormControl className="translation-dropdown-container">
        <Select
          value={language}
          style={{ color: "#b2a6c9" }}
          disableUnderline
          onChange={(e) => changeLanguage(e.target.value)}
          className="translation-dropdown-options-container"
        >
          <MenuItem value={"en"}>EN</MenuItem>
          <MenuItem value={"tr"}>TR</MenuItem>
          <MenuItem value={"es"}>ES</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default LanguageDropdownButton
