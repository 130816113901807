import React from "react"
import Typography from "../../Typography/Typography"
import Button from "../../Button/Button"
import "./Hero.css"
import { useTranslation } from "react-i18next"

const Hero = () => {
  const { t } = useTranslation("common")

  return (
    <section className="hero">
      <div>
        <Typography title text={t("hero.title")} category="hero-title" />
        <div className="hero-content-container">
          <Typography
            text={t("hero.description")}
            category="hero-paragraph"
            className="hero-section-content"
          />
          <div className="hero-section-button-container">
            <Button
              label={t("register.button")}
              url="https://www.eventbrite.com/e/endurance-hackathon-tickets-161741734883"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
