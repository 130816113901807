import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import contactFormEN from "./locales/en/contactForm.json"
import contactFormTR from "./locales/tr/contactForm.json"
import contactFormES from "./locales/es/contactForm.json"
import sponsorsEN from "./locales/en/sponsors.json"
import sponsorsTR from "./locales/tr/sponsors.json"
import sponsorsES from "./locales/es/sponsors.json"
import speakersEN from "./locales/en/speakers.json"
import speakersTR from "./locales/tr/speakers.json"
import speakersES from "./locales/es/speakers.json"
import ticketEN from "./locales/en/ticket.json"
import ticketTR from "./locales/tr/ticket.json"
import ticketES from "./locales/es/ticket.json"
import aboutusEN from "./locales/en/aboutus.json"
import aboutusTR from "./locales/tr/aboutus.json"
import aboutusES from "./locales/es/aboutus.json"
import commonEN from "./locales/en/common.json"
import commonTR from "./locales/tr/common.json"
import commonES from "./locales/es/common.json"
import juryEN from "./locales/en/jury.json"
import juryTR from "./locales/tr/jury.json"
import juryES from "./locales/es/jury.json"
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    contactForm: contactFormEN,
    sponsors: sponsorsEN,
    speakers: speakersEN,
    ticket: ticketEN,
    aboutus: aboutusEN,
    common: commonEN,
    jury: juryEN,
  },
  tr: {
    contactForm: contactFormTR,
    sponsors: sponsorsTR,
    speakers: speakersTR,
    ticket: ticketTR,
    aboutus: aboutusTR,
    common: commonTR,
    jury: juryTR,
  },
  es: {
    contactForm: contactFormES,
    sponsors: sponsorsES,
    speakers: speakersES,
    ticket: ticketES,
    aboutus: aboutusES,
    common: commonES,
    jury: juryES,
  },
}
const lng = localStorage.getItem("language") || "en"
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
export default i18n
