import React from "react"
import Typography from "../../Typography/Typography"
import Button from "../../Button/Button"
import SocialMediaBar from "../../SocialMediaBarSection/Views/SocialMediaBar"
import getScreenWidth from "../../../../utilities/JS/getScreenWidth"
import "./NeedHelp.css"
import { useTranslation } from "react-i18next"

const NeedHelp = () => {
  const { width } = getScreenWidth()
  const { t } = useTranslation("common")

  return (
    <div className="need-help-section-container">
      <div className="need-help-title">
        <Typography
          title
          className="need-help-title"
          text={t("need.more.help")}
        />
      </div>
      <div className="need-help-description">
        <Typography
          category="section-paragraph"
          text={t("need.help.description")}
        />
      </div>
      <div className="need-help-button">
        <Button
          purple
          label={t("contact.us.title")}
          url="https://discord.gg/jDxMZgYu"
        />
      </div>
      {width < 1150 && <SocialMediaBar isFooter />}
    </div>
  )
}

export default NeedHelp
