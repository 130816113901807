import React from "react"
import ContentBox from "../Elements/ContentBox/ContentBox"
import PrizeImages from "../Elements/Prizes/PrizeImages"
import { useTranslation } from "react-i18next"

const Prizes = () => {
  const { t } = useTranslation("common")

  return (
    <div>
      <ContentBox
        title={t("prizes.title")}
        content={t("prizes.description")}
      />
      <PrizeImages />
    </div>
  )
}

export default Prizes
