import axios from "axios"

export const cms = axios.create({
  baseURL: process.env.REACT_APP_CMS_URL,
})

cms.interceptors.response.use(
  (response) => response,
  (err) => {
    console.log(err)
    return err
  }
)
