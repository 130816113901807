import React from "react"
import { ReactComponent as Particle1 } from "../../../images/svg/particles/particle_1.svg"
import { ReactComponent as Particle2 } from "../../../images/svg/particles/particle_2.svg"
import { ReactComponent as Particle3 } from "../../../images/svg/particles/particle_3.svg"

import { ReactComponent as Particle4 } from "../../../images/svg/particles/particle_4.svg"
import { ReactComponent as Particle5 } from "../../../images/svg/particles/particle_5.svg"
import { ReactComponent as Particle6 } from "../../../images/svg/particles/particle_6.svg"
import { ReactComponent as Particle7 } from "../../../images/svg/particles/particle_7.svg"
import { ReactComponent as Particle8 } from "../../../images/svg/particles/particle_8.svg"
import { ReactComponent as Particle9 } from "../../../images/svg/particles/particle_9.svg"

import { ReactComponent as Particle10 } from "../../../images/svg/particles/particle_10.svg"
import { ReactComponent as Particle11 } from "../../../images/svg/particles/particle_11.svg"
import { ReactComponent as Particle12 } from "../../../images/svg/particles/particle_12.svg"
import { ReactComponent as Particle13 } from "../../../images/svg/particles/particle_13.svg"
import { ReactComponent as Particle14 } from "../../../images/svg/particles/particle_14.svg"
import { ReactComponent as Particle15 } from "../../../images/svg/particles/particle_15.svg"
import { ReactComponent as Particle16 } from "../../../images/svg/particles/particle_16.svg"

import { ReactComponent as Particle17 } from "../../../images/svg/particles/particle_17.svg"
import { ReactComponent as Particle18 } from "../../../images/svg/particles/particle_18.svg"
import { ReactComponent as Particle19 } from "../../../images/svg/particles/particle_19.svg"
import { ReactComponent as Particle20 } from "../../../images/svg/particles/particle_20.svg"
import { ReactComponent as Particle21 } from "../../../images/svg/particles/particle_21.svg"
import { ReactComponent as Particle22 } from "../../../images/svg/particles/particle_22.svg"
import { ReactComponent as Particle23 } from "../../../images/svg/particles/particle_23.svg"
import { ReactComponent as Particle24 } from "../../../images/svg/particles/particle_24.svg"
import { ReactComponent as Particle25 } from "../../../images/svg/particles/particle_25.svg"
import { ReactComponent as Particle26 } from "../../../images/svg/particles/particle_26.svg"
import { ReactComponent as Particle27 } from "../../../images/svg/particles/particle_27.svg"
import { ReactComponent as Particle28 } from "../../../images/svg/particles/particle_28.svg"

import "./BackgroundParticles.css"

const BackgroundParticles = () => {
  return (
    <div className="particles">
      <Particle1 className="particle particle-1" />
      <Particle2 className="particle particle-2" />
      <Particle3 className="particle particle-3" />
      <Particle4 className="particle particle-4" />
      <Particle5 className="particle particle-5" />
      <Particle6 className="particle particle-6" />
      <Particle7 className="particle particle-7" />
      <Particle8 className="particle particle-8" />
      <Particle9 className="particle particle-9" />
      <Particle10 className="particle particle-10" />
      <Particle11 className="particle particle-11" />
      <Particle12 className="particle particle-12" />
      <Particle13 className="particle particle-13" />
      <Particle14 className="particle particle-14" />
      <Particle15 className="particle particle-15" />
      <Particle16 className="particle particle-16" />
      <Particle17 className="particle particle-17" />
      <Particle18 className="particle particle-18" />
      <Particle19 className="particle particle-19" />
      <Particle20 className="particle particle-20" />
      <Particle21 className="particle particle-21" />
      <Particle22 className="particle particle-22" />
      <Particle23 className="particle particle-23" />
      <Particle24 className="particle particle-24" />
      <Particle25 className="particle particle-25" />
      <Particle26 className="particle particle-26" />
      <Particle27 className="particle particle-27" />
      <Particle28 className="particle particle-28" />
    </div>
  )
}

export default BackgroundParticles
