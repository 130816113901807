import React, { useEffect } from "react"
import Home from "./components/pages/Home"
import "./utilities/CSS/page.css"

function App() {
  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en")
    }
  }, [])

  return <Home />
}

export default App
