import React from "react"
import { useTranslation } from "react-i18next"
import ContentBox from "../Elements/ContentBox/ContentBox"
import ParticipantCard from "../Elements/Participants/ParticipantCard"

const Participants = () => {
  const { t } = useTranslation("common")

  return (
    <div>
      <ContentBox
        title={t("who.can.attend.title")}
        content={t("who.can.attend.description")}
      />
      <ParticipantCard />
    </div>
  )
}

export default Participants
