import React from "react"
import Typography from "../../../Typography/Typography"
import moment from "moment-timezone"
import "./AgendaCard.css"

const AgendaCard = ({
  time,
  eventTitle,
  speakers,
  eventDescription,
  location,
  isLocalTimeZone,
}) => {
  const convertToLocalTime = (dateTime) => {
    const defaultTimeZone = "GMT"
    const defaultDateTime = moment(dateTime, "DD-MM-YYYY hh:mm A").tz(
      defaultTimeZone,
      true
    )
    const localDateTime = moment(defaultDateTime).local()
    return moment(localDateTime).format("hh:mm A")
  }

  const defaultTime = (dateTime) => {
    return moment(dateTime, "DD-MM-YYYY hh:mm A").format("hh:mm A")
  }

  return (
    <div className={`agenda-card `}>
      <div className="agende-card-time-zones-container">
        {time &&
          (isLocalTimeZone ? (
            <Typography
              category="register-button"
              text={`${convertToLocalTime(time)}`}
              style={{ margin: "5px 0 0 0" }}
              className="time trt"
            />
          ) : (
            <Typography
              category="register-button"
              text={`${defaultTime(time)}`}
              style={{ margin: "5px 0 0 0" }}
              className="time trt"
            />
          ))}
      </div>
      <div className="agenda-card-event-content-container">
        <div className="event-card-title-container">
          <Typography
            category="card-name"
            text={eventTitle}
            className="event-title"
            style={{ color: "#F2F2F2", margin: 0 }}
          />
          {/* { 
            <div className="expand-icon-container" onClick={handleExpandToggle}>
              <DownArrow className="expand-icon" />
            </div>
          ) : (
            <div className="expand-icon-container" onClick={handleExpandToggle}>
              <UpArrow className="expand-icon" />
            </div>
          )} */}
        </div>
        {/* <div className="speakers-container">
          {speakers.map((i, idx) => (
            <AgendaCardSpeaker
              name={i.speakerName}
              role={i.speakerRole}
              thumb={i.thumb}
              key={idx}
            />
          ))}
        </div> */}
        {/* {
          <div className="event-details">
            <Typography category="section-paragraph" text={eventDescription} />
            <div className="location-container">
              <LocationPin className="pin" />
              <Typography category="section-paragraph" text={location} />
            </div>
          </div>
        } */}
      </div>
    </div>
  )
}

export default AgendaCard