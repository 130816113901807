import React, { useEffect, useState } from "react"
import Typography from "../Elements/Typography/Typography"
import ProfileCard from "../Elements/ProfileCard/ProfileCard"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { fetchJury} from "../../api/jury" 
const Jury = () => {
  const { t } = useTranslation("jury")

  const [jury,setJury]= useState([])
  useEffect(() => {
    const getJury = async () => {
      const response = await fetchJury()
      setJury(response.data)
    }

    getJury()  
  }, [])

  return (
    <div className="jury-section-container">
      <Typography
        title
        text={t("jury.title")}
        category="section-title"
        className="jury-section-title"
      />

      <Grid
        container
        style={{ marginBottom: 200 }}
        justify="center"
        className="profile-cards-container"
      >
        {jury.map((juror) => {
          return (
            <ProfileCard
              key={juror.id}
              name={juror.Name}
              role={juror.Role}
              image={juror.Image?.url}
              linkedInURL={juror.Linkedin_url}
            />
          )
        })}
      </Grid>
    </div>
  )
}

export default Jury
