import React,{ useEffect, useState } from "react"
import Typography from "../Elements/Typography/Typography"
import ProfileCard from "../Elements/ProfileCard/ProfileCard"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { fetchSpeakers} from "../../api/speakers" 


const Speakers = () => {
  const { t } = useTranslation("speakers")

  const [speakers,setSpeakers]= useState([])
  useEffect(() => {
    const getSpeakers = async () => {
      const response = await fetchSpeakers()
      setSpeakers(response.data)
    }

    getSpeakers()  
  }, [])

  return (
    <div className="speakers-section-container">
      <Typography
        title
        text={t("speakers.title")}
        category="section-title"
        className="speakers-section-title"
      />

      <Grid
        container
        style={{ marginBottom: 200 }}
        justify="center"
        className="profile-cards-container"
      >
        {speakers.map((speaker) => {
          return (
            <ProfileCard
              key={speaker.id}
              name={speaker.Name}
              role={speaker.Role}
              about={speaker.About}
              image={speaker.Image?.url}
              linkedInURL={speaker.Linkedin_url}
            />
          )
        })}
      </Grid>
    </div>
  )
}

export default Speakers
