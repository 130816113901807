import React, { useState } from "react"
import logo from "../Components/Logo.svg"
import getWindowDimensions from "../../../../utilities/JS/getScreenWidth"
import Navigation from "../../NavbarSection/Views/Navbar"
import LanguageDropdownButton from "../../LanguageDropdownButton/LanguageDropdownButton"
import { useTranslation } from "react-i18next"
import { useScrollTrigger, Slide, AppBar } from "@material-ui/core"
import "./Header.css"

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger({ target: undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = (props) => {
  const { width } = getWindowDimensions()
  const [openMobileNavBar, setOpenMobileNavbar] = useState(false)
  const { t } = useTranslation("common")
  const closeNavbar = () => {
    setOpenMobileNavbar(false)
  }
  return (
    <HideOnScroll {...props}>
      <AppBar color="transparent" elevation={0}>
        <div className="header">
          <div className="header-top-side">
            <div
              className="header-logo"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              <img src={logo} alt="Endurance logo" />
              <div className="header-logo-text">
                <h2>Endurance</h2>
                <p>January 22 & 23</p>
              </div>
            </div>
            {width < 1150 && width > 960 && (
              <div className="navigation-items">
                <Navigation isHeader />
              </div>
            )}
            {width > 960 ? (
              <div className="header-button">
                <a
                  href={t("register.url")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className=" register">{t("register.button")}</button>
                </a>
              </div>
            ) : (
              <div
                onClick={() => setOpenMobileNavbar(!openMobileNavBar)}
                className="burger-menu-container"
              >
                <div className="burger-menu"></div>
              </div>
            )}
          </div>

          {openMobileNavBar && (
            <div className="mobile-navbar">
              <Navigation isMobile closeNavbar={() => closeNavbar()} />
              <div className="header-button">
                <a href={t("register.url")}>
                  <button className="mobile-view register">
                    {t("register.button")}
                  </button>
                </a>
                <LanguageDropdownButton isMobile />
              </div>
            </div>
          )}
        </div>
      </AppBar>
    </HideOnScroll>
  )
}

export default Header
