import React, { useEffect, useState } from "react"
import Typography from "../Elements/Typography/Typography"
import SponsorCard from "../Elements/SponsorSection/SponsorCard"
import { useTranslation } from "react-i18next"
import { fetchSponsors } from "../../api/sponsors"

const Sponsors = () => {
  const { t } = useTranslation("common")

  const [sponsors, setSponsors] = useState([])
  useEffect(() => {
    const getSponsors = async () => {
      const response = await fetchSponsors()
      setSponsors(response.data)
    }
    getSponsors()
  }, [])

  return (
    <div style={{ width: "100%" }}>
      <Typography
        title
        text={t("sponsors.title")}
        category="section-title"
      />
      <div className="sponsor-card-container">
        {

          sponsors.length !== 0 && sponsors.map((sponsor, index) =>
            <SponsorCard
              key={index}
              name={sponsor.Name}
              logo={sponsor.Logo.url}
            />
          )
        }
      </div>

    </div>
  )
}

export default Sponsors
