import React from "react"
import Button from "../Button/Button"
import DiscordLogo from "../../../images/svg/discord_icon.svg"
import InstagramLogo from "../../../images/svg/instagram_icon.svg"
import FacebookLogo from "../../../images/svg/facebook_icon.svg"
import "./FooterCard.css"
import { useTranslation } from "react-i18next"

const FooterCard = () => {
  const { t } = useTranslation("common")

  return (
    <div className="footercard">
      <div className="footer-left">
        <h2 className="footer-left-header">
          {t("get.updates.title")}
        </h2>
        <p className="footer-left-paragraph">
        {t("get.updates.description")}
        </p>
        <div className="footer-left-form">
          <input type="text" className="footer-left-input" placeholder="EMail" />
          <Button label={t("subscribe.button")} purple="true" />
        </div>
      </div>
      <div className="footer-right">
        <h2 className="footer-right-header">
          {t("follow.us.title")}
        </h2>
        <p className="footer-right-paragraph">
          <a
            href="https://discord.gg/vXSx8Whrr3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footer-right-icons"
              src={DiscordLogo}
              alt="facebook-discord"
            />
            Discord
          </a>
        </p>
        <p className="footer-right-paragraph">
          <a
            href="https://www.instagram.com/code.with.endurance/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footer-right-icons"
              src={InstagramLogo}
              alt="facebook-instagram"
            />
            Instagram
          </a>
        </p>
        <p className="footer-right-paragraph">
          <a
            href="https://www.facebook.com/events/558081208506083/permalink/558081218506082"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footer-right-icons"
              src={FacebookLogo}
              alt="facebook-endurance"
            />
            Facebook
          </a>
        </p>
      </div>
    </div>
  )
}

export default FooterCard
