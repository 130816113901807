import React from "react"
import Typography from "../Typography/Typography"
import LinkedInLogo from "../../../images/svg/linkedin.svg"
import "./ProfileCard.css"

const ProfileCard = ({ name, role, about, image, linkedInURL }) => {
  return (
    <div className="profile-card-container">
      <div className="profile-card-image-container">
        <img className="profile-card-image" src={image} alt="" />
        {about ? (
          <div className="profile-card-description-wrapper">
            <Typography
              text={about}
              category="card-description"
              className="profile-card-description"
            />
          </div>
        ) : null}
      </div>
      <div className="profile-card-title-section-wrapper">
        <div className="profile-card-title-wrapper">
          <Typography
            text={name}
            category="card-title"
            className="profile-card-title"
            style={{ color: "#f2f2f2" }}
          />
          <Typography
            text={role}
            category="card-sub-title"
            className="profile-card-sub-title"
          />
        </div>
        <a href={linkedInURL} className="profile-linkedin-link">
          <img src={LinkedInLogo} alt="" />
        </a>
      </div>
    </div>
  )
}

export default ProfileCard
