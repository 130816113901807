import React from "react"
import FaqQuestionsSection from "../Elements/FaqSection/FaqQuestionsSection"

const Faq = () => {
  return (
    <div>
      <FaqQuestionsSection />
    </div>
  )
}

export default Faq
