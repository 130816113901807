import React from "react"
import "./SponsorCard.css"

const SponsorCard = ({name, logo}) => {
  return (
    <div className="sponsor-card-root">
      <div className="sponsor-images-container">
          <div className="sponsor-image-wrapper">
            <img
              src={logo}
              alt={`endurance ${name}`}
              className="sponsor-image"
            />
          </div>
      </div>
    </div>
  )
}

export default SponsorCard
