import FooterCard from "../Elements/FooterSection/FooterCard";
import React from "react";
const Footer = () => {
  return (
    <div className="footer">
      <FooterCard />
    </div>
  );
}

export default Footer;