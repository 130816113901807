import React from "react"
import ReactPlayer from 'react-player'
import { useTranslation } from "react-i18next"
import Typography from "../Typography/Typography"
import Button from "../Button/Button"
import "../../../utilities/CSS/page.css"
import "./AboutUs.css"

const AboutUs = () => {
  const { t } = useTranslation(["aboutus","common"])

  return (
    <section className="abt-container sections-positioning">
      <div className="abt-header">
        <Typography
          title
          category="section-title"
          text={t("common:about.us.title")}
        />
      </div>
      <div className="text-box">
        <Typography
          category="hero-paragraph"
          text={t("about.us.paragraph.1")}
        />
      </div>
      <div className="text-box2">
        <Typography
          category="hero-paragraph"
          text={t("about.us.paragraph.2")}
        />
      </div>
      <div className="register-btn">
        <Button
          label={t("common:register.button")}
          url={t("common:register.url")}
        />
      </div>

      <ReactPlayer 
      url = "https://vimeo.com/571670869" 
      controls = {true}
      width = {"100%"}
      height = "500px"
      />
      
    </section>
  )
}

export default AboutUs
