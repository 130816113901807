import React, { useState, useEffect } from "react"
import AgendaCard from "../components/AgendaCard/AgendaCard"
import Typography from "../../Typography/Typography"
import "./AgendaSection.css"
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch"
import DaysTab from "../components/DaysTab/DaysTab"
import getEvents from "../../../../api/events"
import { useTranslation } from "react-i18next"
import moment from "moment"
const AgendaSection = () => {
  const { t } = useTranslation("common")

  const [isLocalTimeZone, setIsLocalTimeZone] = useState(true)
  const [activeEventDay, setActiveEventDay] = useState(null)
  const [eventCardLimit, setEventCardLimit] = useState(3)
  const [eventDaysDetailed, setEventDaysDetailed] = useState({
    eventDates: [],
    firstDay: [],
    secondDay: [],
  })
  const [currentEventDayDetailed, setCurrentEventDayDetailed] = useState([])

  useEffect(() => {
    const fetchEvents = async () => {
      const { data } = await getEvents()
      let datesHolder = []

      // Find event dates from coming data
      data.forEach((event) => {
        const eventMonthAndDay = moment(event.Datetime).format("LL")
        if (!datesHolder.includes(eventMonthAndDay)) {
          datesHolder.push(eventMonthAndDay)
        }
      })

      // sort event dates
      datesHolder = datesHolder.sort(function (a, b) {
        return new Date(a) - new Date(b)
      })
      setActiveEventDay(datesHolder[0])

      const sortedDates = data.sort(function (a, b) {
        return new Date(a.Datetime) - new Date(b.Datetime)
      })

      // divide all sorted dates to 2 days
      sortedDates.forEach((sortedDate) => {
        if (datesHolder[0] === moment(sortedDate.Datetime).format("LL")) {
          setEventDaysDetailed((prevVal) => {
            return {
              ...prevVal,
              firstDay: [...prevVal.firstDay, sortedDate],
            }
          })
        } else {
          setEventDaysDetailed((prevVal) => {
            return {
              ...prevVal,
              secondDay: [...prevVal.secondDay, sortedDate],
            }
          })
        }
      })

      setEventDaysDetailed((prevVal) => {
        return { ...prevVal, eventDates: datesHolder }
      })
    }

    fetchEvents()
  }, [])

  useEffect(() => {
    if (activeEventDay === eventDaysDetailed.eventDates[0]) {
      setCurrentEventDayDetailed(eventDaysDetailed.firstDay)
    } else {
      setCurrentEventDayDetailed(eventDaysDetailed.secondDay)
    }
  }, [
    eventDaysDetailed.firstDay,
    eventDaysDetailed.secondDay,
    eventDaysDetailed.eventDates,
    activeEventDay,
  ])

  return (
    <div className="agenda">
      <div className="header">
        <Typography
          category="section-title"
          text={t("agenda.title")}
          className="title typography"
        />
        <div className="local-time-zone">
          <Typography
            category="section-title"
            text="GMT"
            style={{ margin: 0 }}
            className="local-time-zone-text"
          />
          <ToggleSwitch
            className="toggle-switch"
            checked={isLocalTimeZone}
            onChange={() => setIsLocalTimeZone(!isLocalTimeZone)}
          />
          <Typography
            category="section-title"
            style={{ margin: 0 }}
            text={t("local.time.zone")}
            className="local-time-zone-text"
          />
        </div>
      </div>
      <div className="date-buttons-container">
        {eventDaysDetailed.eventDates.length !== 0 &&
          eventDaysDetailed.eventDates.map((date) => (
            <DaysTab
              key={date}
              activeEventDay={activeEventDay}
              setActiveEventDay={(text) => {
                setActiveEventDay(text)
                setEventCardLimit(3)
              }}
              text={date}
            />
          ))}
      </div>
      <div className="agenda-items-container">
        {currentEventDayDetailed &&
          currentEventDayDetailed.map(
            (eventDetailed, eventDetailedCardIndex) =>
              eventDetailedCardIndex < eventCardLimit && (
                <AgendaCard
                  key={eventDetailed.id}
                  time={eventDetailed.Datetime}
                  day={eventDetailed.Datetime}
                  eventTitle={eventDetailed.Event_title}
                  isLocalTimeZone={isLocalTimeZone}
                />
              )
          )}
      </div>

      <div>
        {(activeEventDay === eventDaysDetailed.eventDates[0]
          ? eventCardLimit < eventDaysDetailed.firstDay.length
          : eventCardLimit < eventDaysDetailed.secondDay.length) && (
          <span
            onClick={() =>
              activeEventDay === eventDaysDetailed.eventDates[0]
                ? setEventCardLimit(eventDaysDetailed.firstDay.length)
                : setEventCardLimit(eventDaysDetailed.secondDay.length)
            }
          >
            <Typography
              category="pink-button"
              text={t("load.more")}
              className="load-more"
            />
          </span>
        )}
      </div>
    </div>
  )
}

export default AgendaSection
