import React from "react"
import Typography from "../Typography/Typography"
import "./Button.css"
const Button = ({ purple, label, url, size }) => {
  return (
    <button
      className={`main-button-container ${size ? size : null} ${
        purple ? "purple-bg" : null
      }`}
    >
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Typography text={label} category="register-button" />
      </a>
    </button>
  )
}

export default Button
