import React from "react"
import Sponsors from "./Sponsors"
import Speakers from "./Speakers"
import Jury from "./Jury"
import Ticket from "./Ticket"
import FAQ from "./Faq"
import Prizes from "./Prizes"
import Participants from "./Participants"
import AboutUs from "./AboutUs"
import NeedHelp from "../Elements/FaqSection/components/NeedHelp"
import Agenda from "./Agenda"
import Hero from "../Elements/HeroSection/Views/Hero"
import Header from "../Elements/HeaderSection/Views/Header"
import Navbar from "../Elements/NavbarSection/Views/Navbar"
import Particles from "../Elements/BackgroundParticles/BackgroundParticles"
import SocialMediaBar from "../Elements/SocialMediaBarSection/Views/SocialMediaBar"
import getScreenWidth from "../../utilities/JS/getScreenWidth"
import { Element } from "react-scroll"
import Footer from "./Footer"
import "../../utilities/CSS/page.css"
const Home = () => {
  const { width } = getScreenWidth()
  return (
    <React.Fragment>
      <Particles />
      <Element id="home" name="home">
        <Header />
      </Element>
      <div className="sections-positioning">
        {width > 1150 && (
          <React.Fragment>
            <Navbar />
            <SocialMediaBar />
          </React.Fragment>
        )}
        <Element id="home" name="home">
          <Hero />
        </Element>
        <Element id="aboutus" name="aboutus">
          <AboutUs />
        </Element>
        <Element style={{ width: "100%" }} id="agenda" name="agenda">
          <Agenda />
        </Element>
        <Element id="prizes" name="prizes">
          <Prizes />
        </Element>
        <Element id="participants" name="participants">
          <Participants />
        </Element>
        <Element id="speakers" name="speakers">
          <Speakers />
        </Element>
        <Element id="ticket" name="ticket" style={{ width: "100%" }}>
          <Ticket />
        </Element>
        <Element id="sponsors" name="sponsors" style={{ width: "100%" }}>
          <Sponsors />
        </Element>
        <Element id="jury" name="jury">
          <Jury />
        </Element>
        <Element id="faq" name="faq" style={{ width: "100%" }}>
          <FAQ />
        </Element>
        <NeedHelp />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Home
