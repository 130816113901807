import React from "react"
import { useTranslation } from "react-i18next"
import Discount from "../../../images/prizes/discount.png"
import FreeTraining from "../../../images/prizes/free-training.png"
import Typography from "../Typography/Typography"
import "./PrizeImages.css"

const PrizeImages = () => {
  const { t } = useTranslation("common")

  return (
    <div className="prize-images-root">
      <div className="prize-images-container">
        <img
          src={FreeTraining}
          alt="endurance discount"
          className="free-training-img"
        />
        <img src={Discount} alt="endurance free training" className="discount-img" />
      </div>
      <Typography
        category="pink-button"
        text={t("know.more")}
        url="https://www.archisacademy.com/training"
      />
    </div>
  )
}

export default PrizeImages
