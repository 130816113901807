import React, { useState, useEffect, useCallback } from "react"
import Modal from "../../../pages/Modal"
import Facebook from "../../../../images/svg/facebook_icon.svg"
import Instagram from "../../../../images/svg/instagram_icon.svg"
import Share from "../../../../images/svg/share_icon.svg"
import Discord from "../../../../images/svg/discord_icon.svg"
import LanguageDropdownButton from "../../LanguageDropdownButton/LanguageDropdownButton"
import getWindowDimensions from "../../../../utilities/JS/getScreenWidth"
import "./SocialMediaBar.css"

const SocialMediaBar = ({ isFooter }) => {
  const { width } = getWindowDimensions()
  const [showModal, setShowModal] = useState(false)
  const handleModal = () => setShowModal(!showModal)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowModal(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  return (
    <React.Fragment>
      <aside className={`${isFooter ? "footer-icons" : "social"}`}>
        <div onClick={() => handleModal()} className="social-media-icon">
          <img src={Share} alt="" />
        </div>
        <div className="social-media-icon">
          <a
            href="https://discord.gg/vXSx8Whrr3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Discord} alt="" />
          </a>
        </div>
        <div className="social-media-icon">
          <a
            href="https://www.instagram.com/code.with.endurance/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Instagram} alt="" />
          </a>
        </div>
        <div className="social-media-icon">
          <a
            href="https://www.facebook.com/archisacademy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Facebook} alt="" />
          </a>
        </div>
        {showModal && <Modal closeModal={() => handleModal()} />}
      </aside>
      {width > 960 && <LanguageDropdownButton />}
    </React.Fragment>
  )
}

export default SocialMediaBar
