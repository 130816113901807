import React from "react"
import Typhography from "../Typography/Typography"
import "./ParticipantCard.css"
import developers from "../../../images/participants/developers.png"
import designers from "../../../images/participants/designers.png"
import managers from "../../../images/participants/managers.png"
import { useTranslation } from "react-i18next"

const ParticipantCard = () => {
  const { t } = useTranslation("common")

  return (
    <div className="participant-card-root">
      <div className="role-card">
        <img src={developers} alt="endurance developers" />
        <Typhography
          style={{ fontSize: 18, textTransform: "capitalize" }}
          text={t("developers")}
          category="register-button"
        />
      </div>
      <div className="role-card">
        <img src={designers} alt="endurance designers" />
        <Typhography
          style={{ fontSize: 18, textTransform: "capitalize" }}
          text={t("designers")}
          category="register-button"
        />
      </div>
      <div className="role-card">
        <img src={managers} alt="endurance managers" />
        <Typhography
          style={{ fontSize: 18, textTransform: "capitalize" }}
          text={t("managers")}
          category="register-button"
        />
      </div>
    </div>
  )
}

export default ParticipantCard
