import React from "react"
import Typography from "../Typography/Typography"
import MapPin from "../../../images/svg/map-pin.svg"
import Clock from "../../../images/svg/clock.svg"
import LogoWhite from "../../../images/svg/logo-white.svg"
import "./TicketCard.css"

const TicketCard = ({ data }) => {
  return (
    <div className="ticket-card-container">
      <div className="ticket-card-empty-side" >
        <div className="ticket-card-empty-block block-left-1" ></div>
        <div className="ticket-card-empty-block block-left-2" ></div>
        <div className="ticket-card-empty-block block-left-3" ></div>
      </div>
      <div className="ticket-card-data-section" >
        <div className="ticket-card-data-section-left" >
          <div className="ticket-card-name-section" >
            <Typography
              text={data.firstName}
              category="card-name"
              className="ticket-card-firstname white"
            />
            <Typography
              text={data.lastName}
              title
              category="section-title"
              className="ticket-card-lastname"
            />
          </div>
          <div className="ticket-card-location-section" >
            <div className="ticket-card-location-only-section" >
              <img className="label-icon" src={MapPin} alt="" />
              <Typography
                text={data.location}
                category="card-description"
                className="ticket-card-location white"
              />
            </div>
            <div className="ticket-card-time-section" >
              <img className="label-icon" src={Clock} alt="" />
              <Typography
                text={`${data.timeFrom} - ${data.timeTo}`}
                category="card-description"
                className="ticket-card-time"
              />
            </div>
          </div>
          <div className="ticket-card-date-section" >
            <div className="ticket-card-month-section" >
              <Typography
                text={data.month}
                title
                category="card-sub-title"
                className="ticket-card-month"
              />
            </div>
            <div className="ticket-card-date1-section" >
              <Typography
                text={data.date1}
                title
                category="card-sub-title"
                className="ticket-card-date1"
              />
            </div>
            <div className="ticket-card-date2-section" >
              <Typography
                text={data.date2}
                title
                category="card-sub-title"
                className="ticket-card-date2"
              />
            </div>
          </div>
        </div>
        <div className="ticket-card-data-section-right" >
          <div className="ticket-card-data-section-up" >
            <div className="ticket-card-logo-section">
              <img src={LogoWhite} className="ticket-card-logo" alt="" />
            </div>
            <div className="ticket-card-name-date-section">
              <Typography
                text={data.title}
                title
                category="card-sub-title"
                className="ticket-card-title"
              />
              <Typography
                text={`${data.month} ${data.date1} & ${data.date2}`}
                title
                category="card-details"
                className="ticket-card-full-date"
              />
            </div>
          </div>
          <div className="ticket-card-data-section-down" >
            <div className="ticket-card-admit-section">
              <Typography
                text={`Admit ${data.admit}`}
                category="card-description"
                className="ticket-card-admit"
              />
            </div>
            <div className="ticket-card-price-section">
            <Typography
              text={`$${data.price}`}
              title
              category="section-title"
              className="ticket-card-price"
            />
          </div>
          </div>
        </div>
      </div>
      <div className="ticket-card-empty-side" >
        <div className="ticket-card-empty-block block-right-1" ></div>
        <div className="ticket-card-empty-block block-right-2" ></div>
        <div className="ticket-card-empty-block block-right-3" ></div>
      </div>
    </div>
  )
}

export default TicketCard
