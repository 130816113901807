import React from "react"
import Typography from "../Elements/Typography/Typography"
import TicketCard from "../Elements/TicketSection/TicketCard"
import ticketJson from "../../jsonDatabase/ticket.json"
import Button from "../Elements/Button/Button"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"

const Ticket = () => {
  const { t } = useTranslation(["ticket","common"])

  return (
    <div className="ticket-section-container">
      <Typography
        title
        text={t("ticket:ticket.title")}
        category="section-title"
        className="ticket-section-title"
      />

      <Grid container className="ticket-card-grid">
        <TicketCard
          data={ticketJson}
        />
      </Grid>
      <div className="section-button-container">
        <Button
          label={t("ticket:get.ticket")}
          size="large"
          url={t("common:register.url")}
        />
      </div>
    </div>
  )
}

export default Ticket
