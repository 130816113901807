import React from "react"
import PropTypes from "prop-types"
import "./Typography.css"

const Typography = ({ title, category, text, style, className, url }) => {
  return title ? (
    <h1 style={{ ...style }} className={`default-title ${category} ${className}`}>
      {text}
    </h1>
  ) : url ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="typography-link"
    >
      <p
        style={{ ...style }}
        className={`default-paragraph ${category} ${className}`}
      >
        {text}
      </p>
    </a>
  ) : (
    <p style={{ ...style }} className={`default-paragraph ${category} ${className}`}>
      {text}
    </p>
  )
}

Typography.propTypes = {
  title: PropTypes.bool,
  category: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}
export default Typography
