import React from "react"
import Typography from "../Typography/Typography"
import "./ContentBox.css"

const ContentBox = ({ title, content }) => {
  return (
    <div className="content-box-root">
      <Typography title category="section-title" text={title} />
      <Typography
        category="section-paragraph"
        text={content}
        style={{ margin: "20px 0 60px" }}
      />
    </div>
  )
}

export default ContentBox
