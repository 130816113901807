import React from "react"
import { Link } from "react-scroll"
import "./Navbar.css"
import { useTranslation } from "react-i18next"

const Navbar = ({ isHeader, isMobile, closeNavbar }) => {
  const { t } = useTranslation("common")

  return (
    <nav
      className={`navbar ${
        isHeader ? "header-navbar" : isMobile ? "mobile-navbar" : "left-navbar"
      }`}
    >
      <ul>
        <li>
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={-100}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("home.title")}
          </Link>
        </li>
        <li>
          <Link
            to="aboutus"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("about.us.title")}
          </Link>
        </li>
        <li>
          <Link
            to="agenda"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("agenda.title")}
          </Link>
        </li>
        <li>
          <Link
            to="prizes"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("prizes.title")}
          </Link>
        </li>
        <li>
          <Link
            to="participants"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("who.can.attend.title")}
          </Link>
        </li>
        <li>
          <Link
            to="speakers"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("speakers.title")}
          </Link>
        </li>
        <li>
          <Link
            to="ticket"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("tickets.title")}
          </Link>
        </li>
        <li>
          <Link
            to="sponsors"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("sponsors.title")}
          </Link>
        </li>
        <li>
          <Link
            to="jury"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("jury.title")}
          </Link>
        </li>
        <li>
          <Link
            to="faq"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            className="nav-item-link"
            activeClass="active-nav-link"
            onClick={() => isMobile && closeNavbar()}
          >
            {t("faq.title")}
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
