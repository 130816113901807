import React from "react"
import Typography from "../../../Typography/Typography"
import "./DaysTab.css"

const DaysTab = ({ activeEventDay, setActiveEventDay, text }) => {
  return (
    <div className="days">
      <div
        onClick={() => setActiveEventDay(text)}
        className={`${activeEventDay === text ? "selected" : ""}`}
      >
        <Typography
          category="section-title"
          text={text}
          className={`tab left typography ${
            activeEventDay === text ? "selected" : ""
          }`}
        />
      </div>
    </div>
  )
}

export default DaysTab
