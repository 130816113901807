import React, { useState } from "react"
import Typography from "../../Typography/Typography"
import TopArrow from "../../../../images/svg/top-arrow.svg"
import DownArrow from "../../../../images/svg/down-arrow.svg"
import "./FaqCard.css"
const FaqCard = ({ question, answer, isCategoryRules }) => {
  const [showAnswer, setShowAnswer] = useState(false)

  const renderRulesBulletContents = (rulesBulletContents) => {
    return rulesBulletContents.map((rulesBulletContent) => {
      return rulesBulletContent.subbulletcontent ? (
        <ul>
          <li className="faq-card-bullet">
            <Typography
              category="section-paragraph"
              text={rulesBulletContent.bullecontent}
            />
          </li>
          {rulesBulletContent.subbulletcontent.map((subBulletContent) => {
            return (
              <ul>
                <li className="faq-card-bullet">
                  <Typography category="section-paragraph" text={subBulletContent} />
                </li>
              </ul>
            )
          })}
        </ul>
      ) : (
        <ul>
          <li className="faq-card-bullet">
            <Typography
              category="section-paragraph"
              text={rulesBulletContent.bullecontent}
            />
          </li>
        </ul>
      )
    })
  }

  return (
    <div className="faq-card-root">
      <div className="card-question-container">
        <Typography
          style={{ color: "#f2f2f2", margin: 0 }}
          category="hero-pagraph"
          text={question}
        />
        <div
          onClick={() => setShowAnswer((prevVal) => !prevVal)}
          className="faq-card-arrow-button"
        >
          <img src={!showAnswer ? DownArrow : TopArrow} alt="faq arrow button" />
        </div>
      </div>
      {showAnswer && (
        <div className="card-answer-container">
          {isCategoryRules ? (
            <div className="faq-card-rules-answer-category">
              <Typography category="section-paragraph" text={answer.maincontent} />
              <Typography
                category="section-paragraph"
                style={{ fontWeight: 700, color: "#f2f2f2", fontSize: 14 }}
                text={answer.codeconduct.title}
              />
              {renderRulesBulletContents(answer.codeconduct.bulletcontents)}
              <Typography
                category="section-paragraph"
                style={{ fontWeight: 700, color: "#f2f2f2", fontSize: 14 }}
                text={answer.eventrule.title}
              />
              {answer.eventrule.contents.map((eventRuleContent, i) => (
                <Typography
                  category="section-paragraph"
                  text={`${i + 1}. ${eventRuleContent}`}
                />
              ))}
            </div>
          ) : (
            <Typography category="section-paragraph" text={answer} />
          )}
        </div>
      )}
    </div>
  )
}

export default FaqCard
